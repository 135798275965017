import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import { Switch, Route } from "react-router-dom";
import data from "./excel.json";
import GridTable from "@nadavshaar/react-grid-table";
// import * as XLSX from "xlsx";
import Select from "react-dropdown-select";

import * as xlsx from "xlsx";
import excelFile from "../src/Book1.xlsx";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
} from "@syncfusion/ej2-react-grids";

import getColumns from "./getColumn";
import getexcel from "./getexcel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import "./styles.css";
const Home = () => {
  const [rowsData, setRowsData] = useState([]);
  const [ExcelLen, setExcelLen] = useState(0);
  const [excelDataLen, setExcelDataLen] = useState(0);
  const [excelData, setExcelData] = useState([]);
  const [NetWt, setNetWt] = useState("");
  const [StdWt, setStdWt] = useState("");
  const [courierChrg, setCourierCharge] = useState("");

  const [pclen, setPcLength] = useState(0);

  const [PurityValue, setPurityValue] = useState("");
  const [WastageValue, setWastageValue] = useState("");
  const [goldStdValue, setGoldStdValue] = useState(0);

  const [rtgsValue, setRtgs] = useState(0);
  const [rtgsCalCount, setRtgsCount] = useState(0);
  const [excelJson, setExcelJson] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  // const readExcel = (file) => {
  //   const promise = new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsArrayBuffer(file);

  //     fileReader.onload = (e) => {
  //       const bufferArray = e.target.result;

  //       const wb = XLSX.read(bufferArray, { type: "buffer" });

  //       const wsname = wb.SheetNames[0];

  //       const ws = wb.Sheets[wsname];

  //       const data = XLSX.utils.sheet_to_json(ws);

  //       resolve(data);
  //     };

  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });

  //   promise.then((d) => {
  //     setItems(d);
  //   });
  // };

  // get file from the imported url

  useEffect(() => {
    console.log("pclen effect", pclen);
  }, [pclen]);

  const onClickFun = () => {
    console.log("onClickFun inside");
    var request = new XMLHttpRequest();
    request.open("GET", excelFile, true);
    request.responseType = "arraybuffer";
    request.onload = function () {
      /* convert data to binary string */
      var data = new Uint8Array(request.response);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      data = arr.join("");

      //using xlsx library convert file to json
      const workbook = xlsx.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      var json = xlsx.utils.sheet_to_json(worksheet);
      console.log(" funexel ", json);
      funExcel(json);
      setExcelJson(json);
    };
    request.send();
  };
  function funExcel(json) {
    setLoading(true);
    setTimeout(() => {
      setRowsData(json);
      setLoading(false);
    }, 1500);
  }
  const purityOption = [
    {
      value: 14,
      label: "56%",
    },
    {
      value: 18,
      label: "76%",
    },
    {
      value: 22,
      label: "92%",
    },
  ];

  const WastageOption = [
    {
      value: 0.5,
      label: "0.5%",
    },
    {
      value: 1,
      label: "1%",
    },
    {
      value: 1.5,
      label: "1.5%",
    },
    {
      value: 2,
      label: "2%",
    },
    {
      value: 2.5,
      label: "2.5%",
    },
    {
      value: 3,
      label: "3%",
    },
    {
      value: 3.5,
      label: "3.5%",
    },
    {
      value: 4,
      label: "4%",
    },
    {
      value: 4.5,
      label: "4.%",
    },
    {
      value: 5,
      label: "5%",
    },
    {
      value: 5.5,
      label: "5.5%",
    },
    {
      value: 6,
      label: "6%",
    },
    {
      value: 6.5,
      label: "6.5%",
    },
    {
      value: 7,
      label: "7%",
    },
    {
      value: 7.5,
      label: "7.5%",
    },
    {
      value: 8,
      label: "8%",
    },
    {
      value: 8.5,
      label: "8.5%",
    },
    {
      value: 9,
      label: "9%",
    },
    {
      value: 9.5,
      label: "9.5%",
    },
    {
      value: 10,
      label: "10%",
    },
    {
      value: 10.5,
      label: "10.5%",
    },
    {
      value: 11,
      label: "11%",
    },
    {
      value: 11.5,
      label: "11.5%",
    },
    {
      value: 12,
      label: "12%",
    },
    {
      value: 12.5,
      label: "12.5%",
    },
    {
      value: 13,
      label: "13%",
    },
    {
      value: 13.5,
      label: "13.5%",
    },
    {
      value: 14,
      label: "14%",
    },
    {
      value: 14.5,
      label: "14.5%",
    },
    {
      value: 15,
      label: "15.5%",
    },
    {
      value: 16,
      label: "16%",
    },
    {
      value: 16,
      label: "16.5%",
    },
  ];
  const getSelectedExecelData = (
    { rowIndex, data, column, isEdit, event },
    tableManager
  ) => {
    !isEdit &&
      tableManager.rowSelectionApi.getIsRowSelectable(data.id) &&
      tableManager.rowSelectionApi.toggleRowSelection(data.id);
    console.log("rowIndex", rowIndex, data);
    if (excelData.includes(rowIndex)) {
      var excelId = excelData.findIndex((item) => item == rowIndex);
      excelData.splice(excelId, 1);
      // excelData.pop(rowIndex);
    } else {
      excelData.push(rowIndex);
    }
    setExcelLen(excelData.length);
    console.log("exceldata lenght", excelData, excelData.length);
    // console.log("exceldata", rowsData[0].N_wt);

    var netWt = 0;
    var stdWt = 0;
    var goldWt = 0;
    var pcLength = 0;
    if (excelData.length > 0) {
      for (let i = 0; i < excelData.length; i++) {
        console.log(
          "i lenght",
          i,
          excelData.length,
          pcLength + rowsData[excelData[i] - 1].Pc
        );

        netWt = netWt + rowsData[excelData[i] - 1].N_wt;
        stdWt = stdWt + rowsData[excelData[i] - 1].stn_Value;
        goldWt = goldWt + rowsData[excelData[i] - 1].Gold_Std;
        pcLength = pcLength + rowsData[excelData[i] - 1].Pc;
        console.log("goldWtgoldWt===pcLength", goldWt, pcLength);
        setNetWt(netWt);
        setStdWt(stdWt);
        setGoldStdValue(goldWt);
        setPcLength(pcLength);
        setExcelDataLen(53.1 * pcLength);
        // console.log("pclen.length", pclen);

        //FINE RATE
        // var TotalPurity =
        //   ((Number(PurityValue) + Number(WastageValue)) * Number(NetWt)) / 100;
        var TotalPurity = (
          ((Number(PurityValue) + Number(WastageValue)) * Number(NetWt)) / 100 +
          Number(goldStdValue)
        ).toFixed(3);
        //H/M + STN VALUE + GST
        var CashCount = excelDataLen + Number(StdWt) + 0.18 * rtgsCalCount;

        var courierCharge = 600;
        setCourierCharge(courierCharge);
        if (rtgsCalCount > 500000) {
          courierCharge = 600 + ((rtgsCalCount - 50000) / 100000) * 120;
          setCourierCharge(courierCharge);
        }

        var rtgsCount = rtgsValue * TotalPurity + CashCount + courierCharge;
        //(rate* fine)+ hm+stnv+gst

        console.log(
          Number(goldStdValue),
          "rtgd value",
          rtgsValue,
          "TOTAL FINE WT====>",
          TotalPurity,
          "CASH====>",
          CashCount,
          "GOLD====>",
          goldStdValue,
          "COURIER====>",
          courierCharge,

          "TOTAL-==",
          rtgsCount
        );
        setRtgsCount(rtgsCount);
      }
    } else {
      setNetWt("");
      setStdWt("");
      setGoldStdValue("");
      setPcLength(0);
      setExcelDataLen("");
      setExcelLen("");
      console.log("inside else");
    }
  };

  const getPurityFun = (item) => {
    var getPureValue = item[0].label.split("%")[0];
    console.log("purity fun", getPureValue);
    setPurityValue(getPureValue);
  };
  const getWastageFun = (item) => {
    var getwasteValue = item[0].label.split("%")[0];
    console.log("wastage fun", getwasteValue);
    setWastageValue(getwasteValue);
  };

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        console.log("new excel data", json);
        console.log("new excel data", json);

        // var goldStd = 0;
        // for (let i = 0; i < json.length; i++) {
        //   // json[i].Gold_Std =
        //   // goldStd = goldStd + Number(json[i].Gold_Std);
        //   if (json[i].Gold_Std != undefined) {
        //     console.log("====================================");
        //     console.log("json[i].Gold_Std----", json[i].Gold_Std);
        //     console.log("====================================");
        //     var number = parseFloat(json[i].Gold_Std); //Convert to numbers with parseFloat
        //     goldStd += number; //Sum the numbers
        //   }
        //   console.log("goldStd", goldStd);
        //   setGoldStdValue(goldStd);
        // }
        // console.log("goldStd==", goldStd);
        funExcel(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  function trial() {
    console.log("function trial");
  }

  return (
    <>
      {/* <Navbar /> */}
      {/* <section className="hero-section"> */}
      <div className="App">
        {/* <div className="mb-2">
          <Button
            variant="primary"
            className="btnSize"
            size="lg"
            onClick={() => onClickFun()}
          >
            Get Excel data
          </Button>{" "}
        </div> */}
        <div>
          <input
            type="file"
            name="upload"
            id="upload"
            onChange={readUploadFile}
          />
        </div>
        <GridTable
          className="grid"
          rows={rowsData}
          columns={getexcel({ setRowsData })}
          isLoading={isLoading}
          onRowClick={(
            { rowIndex, data, column, isEdit, event },
            tableManager
          ) => {
            getSelectedExecelData(
              { rowIndex, data, column, isEdit, event },
              tableManager
            );
          }}
        />
        <div className="containerCard">
          <Container>
            <Row>
              <Col sm={6}>Total Tag : {ExcelLen > 0 ? ExcelLen : 0} </Col>
              <Col sm={6}>
                H/M PC : {pclen} * 53.1 = {excelDataLen}
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                {" "}
                Net Wt : {pclen > 0 ? Number(NetWt).toFixed(3) : 0}
              </Col>

              <Col sm={6}>
                {" "}
                Stone Value : {pclen > 0 ? Number(StdWt).toFixed(3) * 0.3 : 0}
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                Purity:{" "}
                <Select
                  options={purityOption}
                  onChange={(item) => {
                    getPurityFun(item);
                  }}
                />
              </Col>
              <Col sm={4}>
                Wastage :{" "}
                <Select
                  options={WastageOption}
                  onChange={(item) => {
                    getWastageFun(item);
                  }}
                />
              </Col>
              <Col sm={4}>
                Total Purity : {Number(PurityValue) + Number(WastageValue)} %
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                Fine Weight :
                {/* {(
                  ((Number(PurityValue) + Number(WastageValue)) *
                    Number(NetWt)) /
                  100
                ).toFixed(3)}{" "}
                + {Number(goldStdValue)}{" "} */}
                {(
                  ((Number(PurityValue) + Number(WastageValue)) *
                    Number(NetWt)) /
                    100 +
                  Number(goldStdValue)
                ).toFixed(3)}
              </Col>
              {/* <Col sm={6}>Kundan : {Number(StdWt).toFixed(3) * 1.03}</Col> */}
              <Col sm={6}>
                Kundan : {Number(rtgsValue).toFixed(3) * Number(goldStdValue)}
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                Rtgs Rate :
                <input
                  type="text"
                  value={rtgsValue}
                  onChange={(e) => {
                    console.log("pclen onchange==>", pclen);
                    setRtgs(e.target.value);

                    //RATE
                    var inputVal = Number(e.target.value);

                    //FINE RATE
                    var TotalPurity = (
                      ((Number(PurityValue) + Number(WastageValue)) *
                        Number(NetWt)) /
                        100 +
                      Number(goldStdValue)
                    ).toFixed(3);

                    //H/M + STN VALUE + GST
                    var CashCount =
                      excelDataLen + Number(StdWt) + 0.18 * rtgsCalCount;

                    var courierCharge = 600;
                    setCourierCharge(courierCharge);
                    if (rtgsCalCount > 500000) {
                      courierCharge =
                        600 + ((rtgsCalCount - 50000) / 100000) * 120;
                      setCourierCharge(courierCharge);
                    }

                    // var rtgsCount =
                    //   inputVal * TotalPurity +
                    //   CashCount +
                    //   Number(goldStdValue) +
                    //   courierCharge;

                    var rtgsCount =
                      inputVal * TotalPurity + CashCount + courierCharge;
                    //(rate* fine)+ hm+stnv+gst

                    console.log(
                      Number(goldStdValue),
                      "rtgd value",
                      inputVal,
                      "TOTAL FINE WT====>",
                      TotalPurity,
                      "CASH====>",
                      CashCount,
                      "GOLD====>",
                      goldStdValue,
                      "COURIER====>",
                      courierCharge,

                      "TOTAL-==",
                      rtgsCount
                    );
                    setRtgsCount(rtgsCount);
                  }}
                />{" "}
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                Courier Charge : {Number(courierChrg).toFixed(3)}
              </Col>
            </Row>
            <Row>
              <Col sm={12}>Total Amount :</Col>
            </Row>
            <Row>
              <Col sm={12}>
                {excelDataLen > 0 ? Number(rtgsCalCount).toFixed(3) : 0}
              </Col>
            </Row>
          </Container>
        </div>

        {/* <GridComponent dataSource={data}>
            <ColumnsDirective>
              <ColumnDirective field="Tag No" headerText="Order ID" />
              <ColumnDirective field="CustomerID" headerText="Customer ID" />
              <ColumnDirective field="ShipCountry" headerText="Ship Country" />
            </ColumnsDirective>
          </GridComponent> */}
        {/* <h1>React SPA Home Page</h1> */}
        {/* <GridTable
            className="grid"
            rows={rowsData}
            columns={getexcel({ setRowsData })}
            isLoading={isLoading}
            onRowClick={(
              { rowIndex, data, column, isEdit, event },
              tableManager
            ) => {
              getSelectedExecelData(
                { rowIndex, data, column, isEdit, event },
                tableManager
              );
            }}
          /> */}
      </div>
      {/* </section> */}
    </>
  );
};

const About = () => {
  return (
    <>
      <Navbar />
      <section className="hero-section">
        <p>Welcome to </p>
        <h1>React SPA About Page</h1>
      </section>
    </>
  );
};

const Service = () => {
  return (
    <>
      <Navbar />
      <section className="hero-section">
        <p>Welcome to </p>
        <h1>React SPA Service Page</h1>
      </section>
    </>
  );
};

const Contact = () => {
  return (
    <>
      <Navbar />
      <section className="hero-section">
        <p>Welcome to </p>
        <h1>React SPA Contact Page</h1>
      </section>
    </>
  );
};

const App = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>

      <Route path="/about">
        <About />
      </Route>

      <Route path="/service">
        <Service />
      </Route>

      <Route path="/contact">
        <Contact />
      </Route>
    </Switch>
  );
};

export default App;
