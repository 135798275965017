import React from "react";

const styles = {
  root: {
    position: "relative",
    padding: "0 20px",
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
  },
  img: { width: 50, height: 50 },
  input: {
    position: "absolute",
    height: 28,
    width: "calc(100% - 82px)",
    top: 10,
    right: 20,
    bottom: 0,
    border: "none",
    borderBottom: "1px solid #eee",
    outline: "none",
    fontSize: 16,
    padding: 0,
    fontFamily: "inherit",
  },
  text: {
    marginLeft: 10,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  modalContent: {
    margin: "auto",
    display: "block",
    width: "30%",
    maxWidth: "700",
  },
  modalData: {
    display: "none",
    position: "fixed",
    zIndex: 1 /* Sit on top */,
    paddingTop: "100" /* Location of the box */,
    left: 0,
    top: 0,
    width: "100%" /* Full width */,
    height: "100%" /* Full height */,
    overflow: "auto" /* Enable scroll if needed */,
    backgroundColor: "rgb(0, 0, 0)" /* Fallback color */,
    backgroundColor: "rgba(0, 0, 0, 0.9)" /* Black w/ opacity */,
  },
  /* The Close Button */
  crossBtn: {
    position: "absolute",
    top: 15,
    right: 487,
    color: "#00000",
    fontSize: 40,
    zIndex: 1,
    transition: 0.3,
  },

  // crossBtn:hover,
  // crossBtn:focus : {
  //   color: '#bbb',
  //   cursor: pointer,
  // }
};
const UsernameCell = ({
  tableManager,
  value,
  onChange,
  isEdit,
  data,
  column,
  rowIndex,
  searchText,
  isFirstEditableCell,
  img,
}) => {
  console.log("data====>", data.img);

  var baseUrl =
    "https://jewelflix.disctesting.in/jewel_upload" + "/" + data.img;
  // console.log("img====>", baseUrl);

  function handleOnClick(e) {
    console.log("handleOnClick pressed", e);
    // Get the modal
    var modal = document.getElementById("myModal");
    // Get the image and insert it inside the modal - use its "alt" text as a caption
    var img = document.getElementById("myImg");
    var modalImg = document.getElementById("img01");
    // var captionText = document.getElementById("caption");
    console.log("img.src", img.src);
    var newsrc = e;
    // img.onclick = function(){
    modal.style.display = "block";
    modalImg.src = newsrc;
    // captionText.innerHTML = this.alt;
    // }

    // Get the <span> element that closes the modal
    // var span = document.getElementsByClassName("close")[0];

    // When the user clicks on <span> (x), close the modal
    // span.onclick = function () {
    //   modal.style.display = "none";
    // };
  }

  function clickClose() {
    var modal = document.getElementById("myModal");

    modal.style.display = "none";
  }
  return (
    <>
      <div style={styles.root}>
        <img
          style={styles.img}
          id="myImg"
          onClick={(e) => handleOnClick(e.target.src)}
          src={"https://jewelflix.disctesting.in/jewel_upload/" + data.img}
          alt="avatar"
        />

        {/* modal */}
        <div styles={styles.modalData} id="myModal" class="modal">
          <span style={styles.crossBtn} class="close" onClick={clickClose}>
            &times;
          </span>
          <img style={styles.modalContent} class="modal-content" id="img01" />
          {/* <div id="caption"></div> */}
        </div>
      </div>
    </>
  );
};

export default UsernameCell;
